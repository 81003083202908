import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import { social } from '../../utils/data';

const SocialIcon = () => {
  return (
    <div className='social d-flex justify-content-center'>
      {social.map((item, i) => {
        return (
          <OutboundLink
            key={i}
            className='btn btn-outline-light btn-social mx-1'
            href={item.link}
            target='_blank'
            rel='noopener noreferrer preload'>
            <FontAwesomeIcon size='lg' icon={['fab', item.icon]} />
          </OutboundLink>
        );
      })}
    </div>
  );
};

export default SocialIcon;
