export const headerContent = {
  title: 'Digbijaya Shakya',
  description:
    'I am a Full Stack Engineer primarily focusing on Backend with industry experience building web applications. I have professional experience with Node.js, Angular, AWS SDK and TypeScript. In addition, I also have past experience with Sumo Logic (Big Data Management Tool), React, Solidity and Logic Monitor.'
};

export const skills = [
  {
    icon: 'server',
    title: 'Backend',
    tools: 'Node.js • Express • TypeORM • AWS SDK • Jest'
  },
  {
    icon: 'laptop-code',
    title: 'Frontend',
    tools: 'JavaScript • Angular • GraphQL • Jasmine • Karma • Bootstrap'
  },
  {
    icon: 'database',
    title: 'Tools & Technology',
    tools:
      'PostgreSQL • Elasticsearch • Redis • Docker • Ethereum • Sumo Logic • Logic Monitor'
  }
];

export const experiences = [
  {
    icon: {
      imageName: 'LogicaBeans.png',
      alt: 'LogicaBeans Logo',
      desc: 'LogicaBeans Pvt. Ltd.',
      className: 'company-logo'
    },
    name: 'LogicaBeans Pvt. Ltd.',
    designation: 'Software Developer',
    duration: 'Nov 2018 - Oct 2020'
  },
  {
    icon: {
      imageName: 'ITONICS-Logo-light-background-RGB.png',
      alt: 'ITONICS Logo',
      desc: 'ITONICS Nepal Pvt. Ltd.',
      className: 'company-logo'
    },
    name: 'ITONICS Nepal',
    designation: 'Senior Software Engineer',
    duration: 'Oct 2020 - Present'
  }
];

export const contactInfo = [
  {
    icon: 'map-marked-alt',
    title: 'Address',
    content: 'Lalitpur, Nepal'
  }
];

export const social = [
  {
    icon: 'linkedin',
    link: 'https://www.linkedin.com/in/shakyadigbijaya/'
  },
  {
    icon: 'github',
    link: 'https://www.github.com/dr-shakya'
  },
  {
    icon: 'twitter',
    link: 'https://www.twitter.com/shakyadigbijaya'
  }
];
