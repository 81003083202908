import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { contactInfo } from '../../utils/data';
import SocialIcon from './social';
import ContactForm from './contact-form';

const Contact = () => {
  return (
    <section className='contact-section'>
      <div className='container container--stretch'>
        <div className='text-center'>
          <h3 className='section-heading text-uppercase'>Contact</h3>
        </div>
        <div className='row contact-section__info'>
          {contactInfo.map((info, i) => {
            return (
              <div key={`contact-${i}`} className='col-md-4 mb-3 mb-md-0'>
                <div className='card h-100'>
                  <div className='card-body my-2 text-center'>
                    <FontAwesomeIcon
                      className='card__icon'
                      icon={['fas', info.icon]}
                      size='lg'
                    />
                    <h4 className='card__title'>{info.title}</h4>
                    <div className='divider-custom card__separator'>
                      <div className='divider-custom-line card__line'></div>
                    </div>
                    <div>{info.content}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <ContactForm />
        <SocialIcon />
      </div>
    </section>
  );
};

export default Contact;
