import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactForm = () => {
  return (
    <form
      name='contact'
      method='POST'
      className='contact-form'
      data-netlify='true'
      data-netlify-honeypot='bot-field'>
      <input type='hidden' name='bot-field' />
      <input type='hidden' name='form-name' value='contact' />

      <input
        type='text'
        name='name'
        className='form-control contact-form__input'
        placeholder='Name'
      />
      <input
        type='email'
        name='email'
        className='form-control contact-form__input'
        placeholder='Email'
      />
      <input
        type='text'
        name='subject'
        className='form-control contact-form__input'
        placeholder='Subject'
      />
      <textarea
        name='message'
        className='form-control contact-form__input'
        rows='12'
        placeholder='Message'
      />
      <button type='submit' className='btn btn-outline-light contact-form--btn'>
        Submit
        <FontAwesomeIcon
          className='ml-2 card-icon'
          icon={['fas', 'chevron-circle-right']}
          size='lg'
        />
      </button>
    </form>
  );
};

export default ContactForm;
