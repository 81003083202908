import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { skills } from '../../utils/data';

const Skill = () => {
  return (
    <section className='skill-section' id='skill'>
      <div className='container container--stretch'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h2 className='section-heading skill-animation'>Skill</h2>
            {/* <h3 className='section-subheading'>
                Full Stack Software Developer
              </h3> */}
          </div>
        </div>
        <div className='row text-center'>
          {skills.map((techSkills, i) => {
            return (
              <div
                key={`skill-${i}`}
                className='col-md-4 skill skill-animation'>
                <FontAwesomeIcon
                  className='logo'
                  icon={['fas', techSkills.icon]}
                  size='lg'
                />
                <h4 className='skill-heading'>{techSkills.title}</h4>
                <p className='skill-subheading'>{techSkills.tools}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skill;
