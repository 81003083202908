import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Element } from 'react-scroll';
import Contact from '../components/container/contact';
import Experience from '../components/container/experience';
import Header from '../components/container/header';
import Skill from '../components/container/skill';
import Layout from '../components/layout';
import SEO from '../components/shared/seo';

library.add(fab, fas);

export default () => (
  <Layout>
    <SEO title='Digbijaya Shakya - Portfolio' />
    <Element name='section-header' className='element'>
      <Header />
    </Element>
    <Element name='section-skill' className='element'>
      <Skill />
    </Element>
    <Element name='section-experience' className='element'>
      <Experience />
    </Element>
    <Element name='section-contact' className='element'>
      <Contact />
    </Element>
  </Layout>
);
