import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const Image = (props) => {
  const { alt, imageName, className, desc } = props.logo;
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: {
              extension: { regex: "/(png)|(jpg)|(jpeg)/" }
              sourceInstanceName: { eq: "images" }
            }
          ) {
            edges {
              node {
                base
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const image = data.images.edges.find(
          (edge) => edge.node.base === imageName
        );
        return (
          <>
            {!!image && (
              <Img
                className={className}
                alt={alt}
                title={desc}
                fluid={image.node.childImageSharp.fluid}
                imgStyle={{
                  objectFit: 'contain'
                }}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default Image;
