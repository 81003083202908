import React from 'react';
import { experiences } from '../../utils/data';
import Image from '../shared/image';

const Experience = () => {
  return (
    <section className='experience-section' id='services'>
      <div className='container container--stretch'>
        <div className='text-center'>
          <h3 className='section-heading experience--animation'>Experience</h3>
          {/* <h2 className='section-subheading text-dark'>What We Offer</h2> */}
        </div>
        <div className='row justify-content-center'>
          {experiences.map((experience, i) => {
            return (
              <div
                key={`experience-${i}`}
                className='col-lg-3 col-md-6 mb-0 mb-lg-0'>
                <div>
                  <Image logo={experience.icon} />
                </div>

                <h4 className='experience-heading'>{experience.name}</h4>
                <p className='experience-duration'>[{experience.duration}]</p>
                <p className='experience-subheading'>
                  {experience.designation}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Experience;
