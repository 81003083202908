import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { headerContent } from '../../utils/data';
import SocialIcon from './social';

const Header = () => {
  return (
    <header className='masthead'>
      <div className='container container--stretch d-flex flex-column align-items-center'>
        <h1 className='masthead-heading mb-0'>{headerContent.title}</h1>
        {/* <!-- Icon Divider--> */}
        <div className='divider-custom divider-light'>
          <div className='divider-custom-line'></div>
          <div className='divider-custom-icon'>
            <FontAwesomeIcon icon={['fas', 'code']} size='lg' />
          </div>
          <div className='divider-custom-line'></div>
        </div>
        <SocialIcon />
        {/*<!-- Masthead Subheading-->*/}
        <p className='masthead-subheading'>{headerContent.description}</p>
      </div>
    </header>
  );
};

export default Header;
